.fifth-component {
  background-position: right;
  /* background-size: cover; */
  background-size: 100% 145%;
  background-position-x: 33vw;
  background-repeat: no-repeat;
}

.fifth-component .col {
  padding: 0 5%;
}

.last-slide-header {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.last-slide-logo {
  width: 300px;
}

.fifth-component .cta-button {
  margin-top: 4rem;
}

.fifth-component .subtext-wrapper {
  text-align: left;
  margin-top: 2rem;
}

/* Media Queries */

@media (max-width: 768px) {
  .fifth-component {
    background-position: inherit;
    background-size: cover;
    background-position-x: 0;
    background-repeat: no-repeat;
  }
  .fifth-component .row {
    margin: 0 1rem;
  }
  .fifth-component .col {
    padding: 0 1rem;
  }
}

@media (max-width: 680px) {
  .fifth-component {
    background-position: bottom left;
    background-size: 120vh;
  }
  .last-slide-logo {
    width: 70%;
    position: absolute;
    bottom: 3rem;
  }
  .fifth-component .cta-button {
    margin-top: 7vh;
  }
}
