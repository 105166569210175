.third-component .col {
  padding-left: 5%;
}

.card-container {
  width: 100%;
  margin: 0 auto;
}

.card-wrapper {
  display: flex;
  flex-direction: column;
  max-width: 90%;
  margin: 0 auto;
  margin-top: 2rem;
}

.cards {
  display: flex;
  padding: 25px 1px;
  list-style: none;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
}

.card {
  display: flex;
  flex-direction: column;
  flex: 0 0 100%;
  padding: 20px;
  background: transparent;
  border-radius: 12px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 15%);
  scroll-snap-align: start;
  transition: all 0.2s;
  height: 550px;
  border: 1px solid #fff;
}

.card:not(:last-child) {
  margin-right: 10px;
}

.card:hover {
  color: #fff;
  background: linear-gradient(90deg, #f7931e, #f9cf38);
}

.card-img {
  max-width: 55%;
  margin-top: 2rem;
  margin: 0 auto;
}

.card .card-title {
  font-size: 21px;
  margin-top: 3rem;
  font-family: "Montserrat EB Italic", sans-serif;
  color: var(--color-lighter-text);
}

.card:hover .card-title,
.card:hover .card-content {
  color: var(--color-bg);
}

.card .card-content {
  margin: 20px 0;
  color: #efefef;
}

.card .card-link-wrapper {
  margin-top: auto;
}

.card .card-link {
  display: inline-block;
  text-decoration: none;
  color: white;
  background: var(--color-secondary);
  padding: 6px 12px;
  border-radius: 8px;
  transition: background 0.2s;
}

.card:hover .card-link {
  background: #c00424;
}

.cards::-webkit-scrollbar {
  height: 12px;
}

.cards::-webkit-scrollbar-thumb,
.cards::-webkit-scrollbar-track {
  border-radius: 92px;
}

.cards::-webkit-scrollbar-thumb {
  background: var(--color-primary);
}

.cards::-webkit-scrollbar-track {
  background: #edf2f4;
}

@media (min-width: 300px) {
  .card {
    flex-basis: calc(100% - 35px);
  }

  .card:not(:last-child) {
    margin-right: 20px;
  }
}

@media (min-width: 500px) {
  .card {
    flex-basis: calc(50% - 25px);
  }

  .card:not(:last-child) {
    margin-right: 20px;
  }
}

@media (min-width: 801px) {
  .card {
    flex-basis: calc(calc(100% / 3) - 35px);
  }

  .card:not(:last-child) {
    margin-right: 30px;
  }
}

@media (min-width: 1100px) {
  .card {
    /* flex-basis: calc(25% - 30px); */
    flex-basis: calc(33% - 45px);
  }

  .card:not(:last-child) {
    margin-right: 40px;
  }
}

/* Media Queries */

@media (max-width: 1600px) {
  .cards {
    padding-top: 0;
  }
  .card {
    height: auto;
    min-height: 50vh;
    max-height: calc(100vh - 270px);
  }
  .card-wrapper {
    margin-top: 0;
  }
  .card-img {
    max-width: 30%;
  }
  .card .card-title {
    margin-top: 2rem;
    font-size: 20px;
  }
}

@media (max-width: 1365px) {
  .third-component {
    padding-bottom: 4rem;
  }
  .cards {
    padding-top: 25px;
  }
  .card {
    height: 550px;
    max-height: calc(100vh - 200px);
  }
  .card-img {
    max-width: 55%;
  }
}

@media (max-width: 800px) {
  .card:last-child {
    margin-right: 30px;
  }
}

@media (max-width: 768px) {
  .third-component .col {
    padding: 0 2rem;
  }
}
