.second-component .left .left-content-wrapper {
  display: flex;
  justify-content: baseline;
  height: 100%;
}

.second-component .left .left-content-wrapper > p,
.second-component .left .left-content-wrapper > ul {
  font-size: 22px;
}

.second-component .left .left-content-wrapper ul li {
  margin-bottom: 1rem;
}

/* Media Queries */

@media (max-width: 1600px) {
  .big-header {
    margin-top: 0;
    margin-bottom: 2rem;
  }
  .second-component .left .left-content-wrapper > p,
  .second-component .left .left-content-wrapper > ul {
    font-size: 18px;
  }
}

@media (max-width: 768px) {
  .big-header {
    margin-top: 4rem;
  }
  .second-slide-header-image {
    width: 95vw;
    margin: 0 auto;
    position: relative;
    top: 0;
    left: 8px;
  }
}
