@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Montserrat"), url(./fonts/Mont-med.woff2) format("woff2");
}

@font-face {
  font-family: "Montserrat";
  src: url("./fonts/Montserrat-Bold.woff2") format("woff2"),
    url("./fonts/Montserrat-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  font-weight: 100;
  font-display: swap;
  src: local("Montserrat Thin"),
    url(./fonts/Montserrat-Thin.woff2) format("woff2");
}

@font-face {
  font-family: "Montserrat Thin";
  src: url("./fonts/Montserrat-Thin.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat EB";
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src: local("Montserrat Extra Bold"),
    url(./fonts/Montserrat-ExtraBold.woff2) format("woff2");
}

@font-face {
  font-family: "Montserrat EB Italic";
  font-weight: bold;
  font-display: swap;
  src: url(./fonts/Montserrat-ExtraBoldItalic.woff2) format("woff2"),
    url(./fonts/Montserrat-ExtraBoldItalic.woff) format("woff");
}

html,
body,
body > * {
  margin: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 300;
}

.title {
  margin: 20px auto 0;
  text-align: center;
}

.links {
  display: flex;
  flex-direction: column;
  margin-left: 50px;
}

.links .link {
  margin-top: 20px;
}

.component {
  height: 100%;
  width: 100%;
  display: flex;
  position: relative;
}

.component:focus {
  outline: none;
}

.component h2 {
  color: #efefef;
  text-transform: uppercase;
  font-family: "Montserrat EB Italic", sans-serif;
  /* Temp */
  display: flex;
  align-items: center;
}

.component.first-component {
  background-color: var(--color-bg);
}

.first-component--inside-scroller {
  position: absolute;
  right: 20px;
  top: 100px;
}

.component.second-component {
  background-color: var(--color-bg);
}

.component.third-component {
  background-color: var(--color-bg);
}

.component.fourth-component {
  background-color: var(--color-bg);
}

.component.fifth-component {
  background-color: var(--color-bg);
}

h2 {
  margin: auto;
  text-align: center;
}

.pagination-additional-class {
  display: none !important;
  margin: 0;
  position: fixed;
  top: 20px;
  display: flex;
  justify-content: center;
  width: 100%;
}

.pagination-additional-class > li:not(:last-of-type) > a,
.pagination-additional-class > li:not(:last-of-type) > span {
  margin-right: 50px;
}

.demo-page-contain {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
}

.demo-page-contain__hint {
  margin-bottom: 20px;
}

.arrow-wrapper {
  position: absolute;
  bottom: 1rem;
  right: calc(50% - 100px);
}

.component.fifth-component .arrow-wrapper {
  right: 2rem;
  bottom: 2rem;
}

.arrow-icon {
  width: 80px;
}

.arrow-text {
  color: var(--color-secondary);
  font-family: "Montserrat Thin", sans-serif;
  position: relative;
  left: 12px;
  top: -12px;
  font-size: 14px;
}

.component.fifth-component .arrow-text {
  left: auto;
  right: 10px;
  top: auto;
  bottom: -16px;
}

.component.fifth-component .arrow-icon {
  -moz-transform: scale(-1, -1);
  -o-transform: scale(-1, -1);
  -webkit-transform: scale(-1, -1);
  transform: scale(-1, -1);
  cursor: pointer;
}

@media (max-width: 768px) {
  .pagination-additional-class > li:not(:last-of-type) > a,
  .pagination-additional-class > li:not(:last-of-type) > span {
    margin-right: 5px;
  }
}

.row {
  padding-top: 4rem;
}

.col {
  color: var(--color-lighter-text);
}

.cta-button {
  width: 400px;
  height: 130px;
  border-radius: 0 45px;
  background-color: var(--color-secondary);
  color: var(--color-bg);
  font-size: 2.5rem;
  font-weight: bold;
  text-decoration: none;
  cursor: pointer;
}

.cta-button:hover {
  background-color: #efc429;
}

.subtext-wrapper {
  font-size: 1.3rem;
  color: var(--color-lighter-text);
  text-align: center;
  margin-top: 1rem;
}

.subtext-link {
  color: #3f9eff;
  text-decoration: underline;
  cursor: pointer;
}

/* Helper classes */
.flex-it {
  display: flex;
  align-items: center;
  justify-content: center;
}

.big-header {
  font-size: 40px;
  font-family: "Montserrat EB Italic", sans-serif;
  margin: 2rem 0;
}

/* Media Queries */

@media (max-width: 1365px) {
  html,
  body,
  body > * {
    overflow: auto;
  }
  .arrow-wrapper {
    display: none;
  }
  .component:not(.first-component):not(.fifth-component) {
    height: auto;
  }
}

@media (max-width: 768px) {
  .component:not(.fifth-component) {
    height: auto;
  }
  .row {
    padding-top: 0;
  }
}
