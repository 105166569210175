/* Left half */

.left {
  padding-left: 5%;
  max-width: 45%;
}

.left .left-content-wrapper {
  height: 80%;
  display: flex;
  flex-direction: column;
  align-items: baseline;
}

.first-component .left .left-content-wrapper {
  justify-content: space-between;
}

.first-component .left .first-slide-logo {
  margin-top: 10%;
}

.first-component .left .first-slide-header-title {
  color: var(--color-lighter-text);
  font-size: 3.28rem;
}

/* Right half */

.first-slide-header-image {
  /* position: absolute;
  top: 1rem;
  right: 1rem;
  max-height: 90%; */
  max-height: calc(100% - 80px);
  max-width: 100%;
  position: relative;
  right: -2rem;
  top: -4rem;
}

.first-slide-header-image-mob {
  display: none;
}

/* Media Queries */

@media (max-width: 1600px) and (min-width: 1366px) {
  .first-component .left .first-slide-logo {
    margin-top: 0;
  }
  .left .left-content-wrapper {
    height: 85%;
  }
  .first-component .left .first-slide-header-title {
    font-size: 3rem;
  }
}

@media (max-width: 1279px) {
  .first-component .left .first-slide-header-title {
    font-size: 28px;
  }
  .cta-button {
    width: 300px;
    height: 100px;
  }
  .first-component .left .left-content-wrapper {
    justify-content: space-evenly;
  }
  .first-slide-header-image {
    max-height: none;
    max-width: none;
    width: 120%;
    position: relative;
    right: -30px;
    top: -4rem;
  }
}

@media (max-width: 768px) {
  .col-6 {
    width: 100%;
    max-width: none;
    padding: 0 2rem;
  }
  .first-component .left .left-content-wrapper {
    justify-content: unset;
  }
  .first-component .right {
    display: none;
  }
  .first-slide-header-image-mob {
    display: inline-block;
    width: 95vw;
    margin: 0 auto;
  }
  .first-component .left .first-slide-header-title {
    font-size: 32px;
    margin-bottom: 3rem;
  }
  .first-component .left .first-slide-logo {
    margin-top: 15%;
    margin-bottom: 2rem;
  }
  .register-now-wrapper {
    margin: 2rem auto 0 auto;
  }
}
